.about {
  position: relative;

  #about {
    position: absolute;
    top: -72px;
  }

  &__content {
    padding-top: 46px;
    padding-bottom: 45px;
  }

  &__title {
    margin-bottom: 25px;

    font-size: 25px;
    font-weight: 500;
    line-height: 1.16;
    text-align: center;
    text-transform: uppercase;

    color: map-get($colors, 'main');
  }

  &__list {
    display: flex;
    flex-direction: column;
    row-gap: 23px;
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__image {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 59px;
    // height: 59px;
    margin-bottom: 9px;

    border-radius: 50%;
    // box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.04);

    img {
      // width: 26px;
      width: 36px;
    }
  }

  &__name {
    margin-bottom: 11px;

    font-size: 18px;
    font-weight: 500;
    line-height: 1.17;
    text-align: center;

    color: map-get($colors, 'main');
  }

  &__text {
    font-size: 12px;
    line-height: 1.719;
    text-align: center;

    color: #717171;

    span {
      color: map-get($colors, 'main');
    }
  }
}

@media (min-width: 768px) {
  .about {
    #about {
      top: -84px;
    }

    &::before {
      position: absolute;
      left: -100px;
      bottom: -50px;

      width: 139px;
      height: 139px;

      border-radius: 50%;
      background-image: linear-gradient(
        80deg,
        #0d2e8e 0%,
        rgba(13, 46, 142, 0.35) 0.01%,
        rgba(13, 46, 142, 0) 100%
      );

      content: '';
    }

    &__content {
      padding-top: 76px;
      padding-bottom: 76px;
    }

    &__title {
      margin-bottom: 61px;

      font-size: 40px;
    }

    &__list {
      flex-direction: row;
      flex-wrap: wrap;
      row-gap: 36px;
      column-gap: 30px;
    }

    &__item {
      position: relative;

      align-items: flex-start;
      width: calc(50% - 15px);
      padding-left: 82px;
    }

    &__image {
      position: absolute;
      top: 0;
      left: 0;

      margin-bottom: 0;
    }

    &__name {
      margin-bottom: 13px;

      font-size: 20px;
      text-align: left;
    }

    &__text {
      font-size: 13px;
      text-align: left;
    }
  }
}

@media (min-width: 1200px) {
  .about {
    &::before {
      left: -250px;
      bottom: -150px;

      width: 316px;
      height: 316px;
    }

    &__content {
      padding-top: 64px;
      padding-bottom: 105px;
    }

    &__item {
      width: calc(33.33333% - 30px / 3 * 2);
    }
  }
}
