.footer {
  &__top {
    background-color: map-get($colors, 'bg');
  }

  &__top-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 62px;
    padding-bottom: 44px;
  }

  &__logo {
    width: 86px;
    margin-bottom: 46px;
  }

  &__documents {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 30px;
    margin-bottom: 46px;

    a {
      font-size: 14px;
      line-height: 1.14;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__partners {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-shrink: 0;
    row-gap: 20px;
    margin-bottom: 46px;
    

    a {
      font-size: 14px;
      line-height: 1.14;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__phone {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    img {
      width: 17px;
      margin-right: 11px;
    }

    a {
      font-weight: 700;
      line-height: 1.19;
    }
  }

  &__email {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    img {
      width: 12px;
      margin-right: 8px;
    }

    a {
      font-size: 14px;
      line-height: 1.14;
    }
  }

  &__networks {
    display: flex;
    justify-content: center;
    column-gap: 10px;

    a {
      display: flex;
    }

    img {
      width: 18px;
    }
  }

  &__bottom-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 34px;
    padding-bottom: 36px;
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 20px;
    margin-bottom: 20px;

    button {
      font-size: 12px;
      line-height: 1.17;

      color: rgba(0, 0, 0, 0.44);

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__copyright {
    font-size: 12px;
    line-height: 1.17;

    color: rgba(0, 0, 0, 0.44);
  }
}

@media (min-width: 768px) {
  .footer {
    &__top-content {
      flex-direction: row;
      padding-top: 44px;
      padding-bottom: 41px;
    }

    &__logo {
      margin-right: 34px;
      margin-bottom: 0;
    }

    &__documents {
      align-items: flex-start;
      row-gap: 19px;
      margin-right: 20px;
      margin-bottom: 0;
    }

    &__partners {
      align-items: flex-start;
      row-gap: 19px;
      margin-right: 40px;
      margin-bottom: 0;
    }

    &__info {
      align-items: flex-end;
      flex-shrink: 0;
    }

    &__phone {
      margin-bottom: 11px;
    }

    &__email {
      margin-bottom: 23px;
    }

    &__bottom-content {
      flex-direction: row;
      padding-top: 18px;
      padding-bottom: 21px;
    }

    &__buttons {
      flex-direction: row;
      column-gap: 37px;
      margin-right: auto;
      margin-bottom: 0;
    }
  }
}

@media (min-width: 1200px) {
  .footer {
    &__top-content {
      padding-bottom: 50px;
    }

    &__logo {
      margin-right: 158px;
    }

    &__documents {
      flex-grow: 1;
    }

    &__partners {
      flex-grow: 2;
    }

    &__email {
      margin-bottom: 20px;
    }

    &__bottom-content {
      padding-top: 24px;
      padding-bottom: 24px;
    }

    &__buttons {
      column-gap: 150px;

      button {
        font-size: 14px;
      }
    }

    &__copyright {
      font-size: 14px;
    }
  }
}
